import React from 'react'
// 加载基础组价
const MonitorLibraryContainer = Loader.loadBusinessComponent('MonitorLibrary', 'MonitorLibraryContainer')

const epidemicPersonTrackLibrary = () => {
  const libTypeInfo = {
    libType: 5,
    libLabel: '隔离人员库',
    libPeople: '隔离人员',
    authAction: 'epidemicPersonTrackLibraryManage',
    moduleName: 'epidemicPersonTrackLibrary'
  }
  return <MonitorLibraryContainer libTypeInfo={libTypeInfo} />
}

export default epidemicPersonTrackLibrary
